.TextBlocks {
    &__block {
        flex: 1 1 50%;

        max-width: 50%;
        padding-right: 3em;

        h3 {margin-bottom: -0.5em; margin-top: 0;}
        p {margin-bottom: 0.5em}
        a {margin-top: -1em;}

        @media (max-width: 900px) {
            max-width: 25em;
            padding-right: 0;
            flex: 1 1 100%;

            & + .TextBlocks__block {
                margin-top: 2em;
            }
        }
    }

    display: flex;
    flex-wrap: wrap;

    @media (max-width: 900px) {
        flex-wrap: wrap;
    }

    &--grid {
        @extend .TextBlocks;
        .TextBlocks__block {
            margin-top: 3em;

            @media (max-width: 900px) {
                margin-top: 0;
            }
        }
    }
}
