.ContactForm {
    input, textarea {
        @include placeholder {
            font-weight: 400;
            color: rgba($c-black,0.5);
        }

        -webkit-appearance: none;

        font-size: 1em;
        background: none;
        color: $c-black;

        outline: none;
        font-family: $f-title;

        border:1px solid rgba($c-black, 0.3);
        border-radius: 0.2em;
        font-family: $f-body;

        padding: 0.5em 1em;

        width: 100%;
        max-width: 30em;

        @include transition(background 0.3s 0s ease-in-out);
    }

    input:active, input:focus, textarea:active, textarea:focus {
        border:1px solid rgba($c-black, 0.8);
    }

    textarea {
        resize: none;
        height: 7em;
        max-height: 7em;
    }

    input + input {
        margin-top: 0.5em;
    }

    h3 {
        margin-bottom: 1em;
    }

    &__success {
        margin-top: 1em;
        color: $c-primary;
    }
    &__error {
        margin-top: 1em;
        color: $c-red;
    }

    &__Services {
        margin: 0 -0.5em;
    }

    &__Estimate {
        font-family: $f-cta;
    }

    &__ServiceSelector {
        display: inline-block;
        padding: 0.5em 0.8em;
        border: 1px solid rgba($c-black, 0.5);
        transition: all 0.2s $ease-in-out-cubic;
        border-radius: 5px;
        cursor: pointer;
        font-weight: 700;
        font-family: $f-cta;
        box-sizing: border-box!important;
        margin: 0.5em;

        &:hover {
            background: rgba($c-black, 0.05);
        }

        &:active, &:focus {
            background: rgba($c-black, 0.1);
        }

        &--selected {
            @extend .ContactForm__ServiceSelector;
            border: 1px solid $c-primary;
            color: white;
            background: $c-primary;

            &:hover {
                background: darken($c-primary, 10%);
            }

            &:active, &:focus {
                background: darken($c-primary, 20%);
            }
        }
    }
}

@keyframes a-shake {
    10%, 90% {
        @include transform(translate3d(-0.1em, 0, 0));
    }
    20%, 80% {
        @include transform(translate3d(0.2em, 0, 0));
    }
    30%, 50%, 70% {
        @include transform(translate3d(-0.4em, 0, 0));
    }
    40%, 60% {
        @include transform(translate3d(0.4em, 0, 0));
    }
}
