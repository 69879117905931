.PrimaryCTA {
    outline: none;
    border: none;

    display: inline-block;
    padding: 0.8em 1.4em;
    border-radius: 5px;

    box-shadow: 0 4px 10px rgba(0,0,0,0.26);

    font-family: $f-cta;
    font-size: 0.9em;
    font-weight: bold;
    letter-spacing: (1/20)*1em;

    color: white;
    background: $c-primary;

    cursor: pointer;

    transition: all 0.3s $ease-in-out-quad;

    -webkit-appearance: none;

    &:hover {
        box-shadow: 0 6px 14px rgba(0,0,0,0.24);
        background: darken($c-primary, 2%);
    }
    &:active, &:focus {
        box-shadow: 0 3px 8px rgba(0,0,0,0.28);
        background: darken($c-primary, 4%);
    }
    &:disabled {
        cursor: default;
        background: rgba($c-black, 0.5);
        box-shadow: none;
    }
}
