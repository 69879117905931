.LogoGrid {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    text-align: center;

    @media (max-width: 500px) {
        flex-direction: column;
        flex-wrap: no-wrap;
        justify-content: center;
    }

    &__logo {
        width: 12em;
        padding: 1em;
        position: relative;
        height: 5em;
        display: flex;
        align-items: center;
        justify-content: center;
        filter: grayscale(100%);

        img {
            max-width: 90%;
            max-height: 90%;
        }

        &:hover {
            // filter:none;
        }

        @media (max-width: 850px) {
            width: 10em;
        }

        @media (max-width: 600px) {
            width: 8em;
        }

        @media (max-width: 500px) {
            width: auto;
        }
    }
}
