.l- {
    &wrap { padding: 0 (50/20)*1em; }
    &mpw { max-width: 30em; }
    &mpw30 { max-width: 30em; }

    &mtop-1 { margin-top: 1em; }
    &mbottom-1 { margin-bottom: 1em; }
    &mleft-1 { margin-left: 1em; }
    &mright-1 { margin-right: 1em; }

    &mtop-2 { margin-top: 2em; }
    &mbottom-2 { margin-bottom: 2em; }
    &mleft-2 { margin-left: 2em; }
    &mright-2 { margin-right: 2em; }

    &two-columns {
        display: flex;
        align-items: flex-start;

        @media (max-width: 900px) {
            flex-wrap: wrap;
        }
    }
}

.l-page-content {
    margin: 7em auto;
    max-width: 55em;
    position: relative;
    overflow: hidden;
    @media (max-width: 500px) {
        margin: 3.5em auto;
    }
}

section + section {
    margin-top: 4em;
}

section[name='contactus'] {
    .l-two-columns {
        justify-content: space-between;
        text-align: center;
      }
}

section[name='process'] {
    img {
        max-width: 40em;
        height: auto;
        align-self: center;
        margin-bottom: 3em;
    }
}

section[name='founders'] {
    text-align: center;
    img {
        width: 100px;
        height: 100px;
        border-radius: 50px;
    }
    h3{
        a{
            margin-right: 5px;
        }
    }
    h2, p{
        text-align: left;
    }
}

section[name='service'] {
    h2 {
        margin: 2rem 0 0 0;
        @media (max-width: 500px) {
            margin: 2rem 0;
        }
    }
}

section[name='caseStudy']{
    padding: 1em 2em;
    border-radius: 1em;
    background: rgba(67,80,228, 0.1);

    @media (max-width: 500px) {
        h2 {
            font-size: 100%;
        }
        margin: 2rem -2em;
    }

    img {
        max-width: 100%;
    }

    .web {
        margin-top: 20px;
    }

    .mobile {
        margin-top: 1em;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @media (max-width: 500px) {
            flex-direction: column;
        }

        >div{
            flex-basis: 50%;
            display: flex;
            flex-direction: column;

            .viewBox {
                position: relative;
                align-self: center;
                width: 60%;
                display: flex;
                flex-direction: column;
                // justify-content: center;
                // align-items: center;
                // height: 545px;

                @media (max-width: 500px) {
                    width: 90%;
                    height: 545px;
                }

                img {
                    max-width: 100%;
                    z-index: 2;

                    @media (max-width: 500px) {
                        max-width: 97%;
                    }
                }
                img.inner {
                    position: absolute;
                    top: 2.5%;
                    left: 6.5%;
                    max-width: 87%;
                    z-index: 1;
                    border-radius: 1em;
                }
                img.outer {
                    @media (max-width: 500px) {
                        display: none;
                    }
                }
                overflow: hidden;
            }

            &.android{
                .viewBox {
                    width: 62%;
                    @media (max-width: 500px) {
                        width: 90%;
                    }
                    img.inner {
                        position: absolute;
                        top: 6%;
                        left: 2.5%;
                        max-width: 95%;
                        z-index: 1;
                        border-radius: 0;

                        @media (max-width: 500px) {
                            max-width: 92%;
                        }
                    }
                }

            }
            span{
                margin: 1em 1rem 0 1rem;
            }
        }
    }
}

.react-rotating-text-cursor {
    animation: blinking-cursor 0.8s cubic-bezier(0.68, 0.01, 0.01, 0.99) 0s infinite;
}

@keyframes blinking-cursor {
0% {
    opacity: 0;
}
50% {
    opacity: 1;
}
100% {
    opacity: 0;
}
}