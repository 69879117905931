.LinkWithArrow {
    position: relative;

    &:after {
        content: '\2192';
        position: absolute;
        left: 100%;
        margin-left: 0.3em;
        margin-top: 0em;
        color: inherit;
        font-size: 1.2em;

        opacity: 0;
        transform: translateX(-0.5em);
        transition: all 0.3s $ease-out-quart;
    }

    &:hover {
        // text-decoration: underline;
        &:after {
            opacity: 1;
            transform: translateX(0);
        }
    }
}
