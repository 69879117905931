html, body {
    padding: 0;
    margin: 0;
    font-family: $f-body;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;

    @include size(100% 100%);
    @include font-feature-settings("liga", "kern");

    text-rendering: geometricPrecision;
    -webkit-font-smoothing: antialiased;
    -webkit-overflow-scrolling: touch;
}

html {
}

body {
    @media (max-width: 500px) {
        font-size: 0.9em;
    }
}

* {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-tap-highlight-color: $c-primary;
    -webkit-text-size-adjust: 100%;
}

h1, h2, h3, h4, h5, h6 {
    font-family: $f-title;
    color: $c-text;
    font-weight: 600;
    margin: 2rem 0;
}

h1 {
    font-size: (65/20)*100%;
    line-height: (85/65)*1em;
    //letter-spacing: 100%*(0.5/16);
    @media (max-width: 600px) {
        font-size: (45/20)*100%;
    }
}

h3 {
    font-size: (26/20)*100%;
    line-height: (40/26)*1em;
}

p {
    line-height: 1.5em;
    letter-spacing: (0.3/20)*1em;
    color: $c-text;
    font-family: $f-body;
    margin: 1rem 0;
}

a {
    text-decoration: none;
    color: $c-primary;
    font-weight: 600;
    font-family: $f-cta;
    display: inline-block;

    &:visited {
        @extend a;
    }
}
