.GlobalNav {
    margin: 1.6em 0;
    display: flex;
    justify-content: space-between;
    img {
        @include size(auto 1.5em);
    }

    @media (max-width: 500px) {
        .SocialLinks {
            display: none;
        }
    }
}
