.SocialLinks {
    display: flex;
    align-items: center;
    font-size: 1.3em;
    justify-content: center;

    a {
        color: $c-black;
        margin: 0 0.3em;
        transition: all 0.2s $ease-in-out-sine;

        &:hover {
            color: $c-primary;
            transform: scale(1.1);
        }

        &.phone {
            font-size: 1rem;
        }
    }

    &:before, &:after {
        content: '';
        position: relative;
        display: inline-block;
        @include size(1em 2px);
        background: rgba($c-black, 1);
        margin: 0 0.3em;
    }
}
