@charset "UTF-8";
html, body {
  padding: 0;
  margin: 0;
  font-family: "Palanquin";
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  height: 100%;
  width: 100%;
  -webkit-font-feature-settings: "liga", "kern";
  -moz-font-feature-settings: "liga", "kern";
  -ms-font-feature-settings: "liga", "kern";
  font-feature-settings: "liga", "kern";
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 500px) {
  body {
    font-size: 0.9em;
  }
}

* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-tap-highlight-color: #4350E4;
  -webkit-text-size-adjust: 100%;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Poppins";
  color: #2C2D2F;
  font-weight: 600;
  margin: 2rem 0;
}

h1 {
  font-size: 325%;
  line-height: 1.3076923077em;
}
@media (max-width: 600px) {
  h1 {
    font-size: 225%;
  }
}

h3 {
  font-size: 130%;
  line-height: 1.5384615385em;
}

p {
  line-height: 1.5em;
  letter-spacing: 0.015em;
  color: #2C2D2F;
  font-family: "Palanquin";
  margin: 1rem 0;
}

a, a:visited {
  text-decoration: none;
  color: #4350E4;
  font-weight: 600;
  font-family: "Source Sans Pro";
  display: inline-block;
}
.is-highlighted {
  color: #4350E4;
}

.PrimaryCTA {
  outline: none;
  border: none;
  display: inline-block;
  padding: 0.8em 1.4em;
  border-radius: 5px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.26);
  font-family: "Source Sans Pro";
  font-size: 0.9em;
  font-weight: bold;
  letter-spacing: 0.05em;
  color: white;
  background: #4350E4;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  -webkit-appearance: none;
}
.PrimaryCTA:hover {
  box-shadow: 0 6px 14px rgba(0, 0, 0, 0.24);
  background: #3a48e3;
}
.PrimaryCTA:active, .PrimaryCTA:focus {
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.28);
  background: #313fe1;
}
.PrimaryCTA:disabled {
  cursor: default;
  background: rgba(44, 45, 47, 0.5);
  box-shadow: none;
}

.LinkWithArrow {
  position: relative;
}
.LinkWithArrow:after {
  content: "→";
  position: absolute;
  left: 100%;
  margin-left: 0.3em;
  margin-top: 0em;
  color: inherit;
  font-size: 1.2em;
  opacity: 0;
  transform: translateX(-0.5em);
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.LinkWithArrow:hover:after {
  opacity: 1;
  transform: translateX(0);
}

.GlobalNav {
  margin: 1.6em 0;
  display: flex;
  justify-content: space-between;
}
.GlobalNav img {
  height: 1.5em;
  width: auto;
}
@media (max-width: 500px) {
  .GlobalNav .SocialLinks {
    display: none;
  }
}

.TextBlocks, .TextBlocks--grid {
  display: flex;
  flex-wrap: wrap;
}
.TextBlocks__block {
  flex: 1 1 50%;
  max-width: 50%;
  padding-right: 3em;
}
.TextBlocks__block h3 {
  margin-bottom: -0.5em;
  margin-top: 0;
}
.TextBlocks__block p {
  margin-bottom: 0.5em;
}
.TextBlocks__block a {
  margin-top: -1em;
}
@media (max-width: 900px) {
  .TextBlocks__block {
    max-width: 25em;
    padding-right: 0;
    flex: 1 1 100%;
  }
  .TextBlocks__block + .TextBlocks__block {
    margin-top: 2em;
  }
}
@media (max-width: 900px) {
  .TextBlocks, .TextBlocks--grid {
    flex-wrap: wrap;
  }
}
.TextBlocks--grid .TextBlocks__block {
  margin-top: 3em;
}
@media (max-width: 900px) {
  .TextBlocks--grid .TextBlocks__block {
    margin-top: 0;
  }
}

.LogoGrid {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  text-align: center;
}
@media (max-width: 500px) {
  .LogoGrid {
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: center;
  }
}
.LogoGrid__logo {
  width: 12em;
  padding: 1em;
  position: relative;
  height: 5em;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: grayscale(100%);
}
.LogoGrid__logo img {
  max-width: 90%;
  max-height: 90%;
}
@media (max-width: 850px) {
  .LogoGrid__logo {
    width: 10em;
  }
}
@media (max-width: 600px) {
  .LogoGrid__logo {
    width: 8em;
  }
}
@media (max-width: 500px) {
  .LogoGrid__logo {
    width: auto;
  }
}

.ContactBlock {
  display: flex;
  flex-direction: column;
  text-align: center;
  border: 1px solid rgba(44, 45, 47, 0.1);
  border-radius: 10px;
  font-size: 1.3em;
  padding: 2em;
}
.ContactBlock > a {
  color: #2C2D2F;
  font-family: "Source Sans Pro";
  font-weight: 500;
  letter-spacing: 0.0384615385em;
  line-height: 150%;
  margin-bottom: 0.5em;
  transition: color 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}
.ContactBlock > a:hover {
  color: #4350E4;
}
@media (max-width: 900px) {
  .ContactBlock {
    width: 100%;
    margin-top: 2em;
    align-items: center;
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 0;
    border: none;
    border-top: 1px solid rgba(44, 45, 47, 0.1);
  }
}

.SocialLinks {
  display: flex;
  align-items: center;
  font-size: 1.3em;
  justify-content: center;
}
.SocialLinks a {
  color: #2C2D2F;
  margin: 0 0.3em;
  transition: all 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}
.SocialLinks a:hover {
  color: #4350E4;
  transform: scale(1.1);
}
.SocialLinks a.phone {
  font-size: 1rem;
}
.SocialLinks:before, .SocialLinks:after {
  content: "";
  position: relative;
  display: inline-block;
  height: 2px;
  width: 1em;
  background: #2c2d2f;
  margin: 0 0.3em;
}

.ContactForm input, .ContactForm textarea {
  -webkit-appearance: none;
  font-size: 1em;
  background: none;
  color: #2C2D2F;
  outline: none;
  font-family: "Poppins";
  border: 1px solid rgba(44, 45, 47, 0.3);
  border-radius: 0.2em;
  font-family: "Palanquin";
  padding: 0.5em 1em;
  width: 100%;
  max-width: 30em;
  -webkit-transition: background 0.3s 0s ease-in-out;
  -moz-transition: background 0.3s 0s ease-in-out;
  transition: background 0.3s 0s ease-in-out;
}
.ContactForm input::-webkit-input-placeholder, .ContactForm textarea::-webkit-input-placeholder {
  font-weight: 400;
  color: rgba(44, 45, 47, 0.5);
}
.ContactForm input::-moz-placeholder, .ContactForm textarea::-moz-placeholder {
  font-weight: 400;
  color: rgba(44, 45, 47, 0.5);
}
.ContactForm input:-moz-placeholder, .ContactForm textarea:-moz-placeholder {
  font-weight: 400;
  color: rgba(44, 45, 47, 0.5);
}
.ContactForm input:-ms-input-placeholder, .ContactForm textarea:-ms-input-placeholder {
  font-weight: 400;
  color: rgba(44, 45, 47, 0.5);
}
.ContactForm input:active, .ContactForm input:focus, .ContactForm textarea:active, .ContactForm textarea:focus {
  border: 1px solid rgba(44, 45, 47, 0.8);
}
.ContactForm textarea {
  resize: none;
  height: 7em;
  max-height: 7em;
}
.ContactForm input + input {
  margin-top: 0.5em;
}
.ContactForm h3 {
  margin-bottom: 1em;
}
.ContactForm__success {
  margin-top: 1em;
  color: #4350E4;
}
.ContactForm__error {
  margin-top: 1em;
  color: #c0392b;
}
.ContactForm__Services {
  margin: 0 -0.5em;
}
.ContactForm__Estimate {
  font-family: "Source Sans Pro";
}
.ContactForm__ServiceSelector, .ContactForm__ServiceSelector--selected {
  display: inline-block;
  padding: 0.5em 0.8em;
  border: 1px solid rgba(44, 45, 47, 0.5);
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  border-radius: 5px;
  cursor: pointer;
  font-weight: 700;
  font-family: "Source Sans Pro";
  box-sizing: border-box !important;
  margin: 0.5em;
}
.ContactForm__ServiceSelector:hover, .ContactForm__ServiceSelector--selected:hover {
  background: rgba(44, 45, 47, 0.05);
}
.ContactForm__ServiceSelector:active, .ContactForm__ServiceSelector--selected:active, .ContactForm__ServiceSelector:focus, .ContactForm__ServiceSelector--selected:focus {
  background: rgba(44, 45, 47, 0.1);
}
.ContactForm__ServiceSelector--selected {
  border: 1px solid #4350E4;
  color: white;
  background: #4350E4;
}
.ContactForm__ServiceSelector--selected:hover {
  background: #1f2dd5;
}
.ContactForm__ServiceSelector--selected:active, .ContactForm__ServiceSelector--selected:focus {
  background: #1824a9;
}

@keyframes a-shake {
  10%, 90% {
    -webkit-transform: translate3d(-0.1em, 0, 0);
    -moz-transform: translate3d(-0.1em, 0, 0);
    -ms-transform: translate3d(-0.1em, 0, 0);
    -o-transform: translate3d(-0.1em, 0, 0);
    transform: translate3d(-0.1em, 0, 0);
  }
  20%, 80% {
    -webkit-transform: translate3d(0.2em, 0, 0);
    -moz-transform: translate3d(0.2em, 0, 0);
    -ms-transform: translate3d(0.2em, 0, 0);
    -o-transform: translate3d(0.2em, 0, 0);
    transform: translate3d(0.2em, 0, 0);
  }
  30%, 50%, 70% {
    -webkit-transform: translate3d(-0.4em, 0, 0);
    -moz-transform: translate3d(-0.4em, 0, 0);
    -ms-transform: translate3d(-0.4em, 0, 0);
    -o-transform: translate3d(-0.4em, 0, 0);
    transform: translate3d(-0.4em, 0, 0);
  }
  40%, 60% {
    -webkit-transform: translate3d(0.4em, 0, 0);
    -moz-transform: translate3d(0.4em, 0, 0);
    -ms-transform: translate3d(0.4em, 0, 0);
    -o-transform: translate3d(0.4em, 0, 0);
    transform: translate3d(0.4em, 0, 0);
  }
}
.l-wrap {
  padding: 0 2.5em;
}
.l-mpw {
  max-width: 30em;
}
.l-mpw30 {
  max-width: 30em;
}
.l-mtop-1 {
  margin-top: 1em;
}
.l-mbottom-1 {
  margin-bottom: 1em;
}
.l-mleft-1 {
  margin-left: 1em;
}
.l-mright-1 {
  margin-right: 1em;
}
.l-mtop-2 {
  margin-top: 2em;
}
.l-mbottom-2 {
  margin-bottom: 2em;
}
.l-mleft-2 {
  margin-left: 2em;
}
.l-mright-2 {
  margin-right: 2em;
}
.l-two-columns {
  display: flex;
  align-items: flex-start;
}
@media (max-width: 900px) {
  .l-two-columns {
    flex-wrap: wrap;
  }
}

.l-page-content {
  margin: 7em auto;
  max-width: 55em;
  position: relative;
  overflow: hidden;
}
@media (max-width: 500px) {
  .l-page-content {
    margin: 3.5em auto;
  }
}

section + section {
  margin-top: 4em;
}

section[name=contactus] .l-two-columns {
  justify-content: space-between;
  text-align: center;
}

section[name=process] img {
  max-width: 40em;
  height: auto;
  align-self: center;
  margin-bottom: 3em;
}

section[name=founders] {
  text-align: center;
}
section[name=founders] img {
  width: 100px;
  height: 100px;
  border-radius: 50px;
}
section[name=founders] h3 a {
  margin-right: 5px;
}
section[name=founders] h2, section[name=founders] p {
  text-align: left;
}

section[name=service] h2 {
  margin: 2rem 0 0 0;
}
@media (max-width: 500px) {
  section[name=service] h2 {
    margin: 2rem 0;
  }
}

section[name=caseStudy] {
  padding: 1em 2em;
  border-radius: 1em;
  background: rgba(67, 80, 228, 0.1);
}
@media (max-width: 500px) {
  section[name=caseStudy] {
    margin: 2rem -2em;
  }
  section[name=caseStudy] h2 {
    font-size: 100%;
  }
}
section[name=caseStudy] img {
  max-width: 100%;
}
section[name=caseStudy] .web {
  margin-top: 20px;
}
section[name=caseStudy] .mobile {
  margin-top: 1em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media (max-width: 500px) {
  section[name=caseStudy] .mobile {
    flex-direction: column;
  }
}
section[name=caseStudy] .mobile > div {
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
}
section[name=caseStudy] .mobile > div .viewBox {
  position: relative;
  align-self: center;
  width: 60%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
@media (max-width: 500px) {
  section[name=caseStudy] .mobile > div .viewBox {
    width: 90%;
    height: 545px;
  }
}
section[name=caseStudy] .mobile > div .viewBox img {
  max-width: 100%;
  z-index: 2;
}
@media (max-width: 500px) {
  section[name=caseStudy] .mobile > div .viewBox img {
    max-width: 97%;
  }
}
section[name=caseStudy] .mobile > div .viewBox img.inner {
  position: absolute;
  top: 2.5%;
  left: 6.5%;
  max-width: 87%;
  z-index: 1;
  border-radius: 1em;
}
@media (max-width: 500px) {
  section[name=caseStudy] .mobile > div .viewBox img.outer {
    display: none;
  }
}
section[name=caseStudy] .mobile > div.android .viewBox {
  width: 62%;
}
@media (max-width: 500px) {
  section[name=caseStudy] .mobile > div.android .viewBox {
    width: 90%;
  }
}
section[name=caseStudy] .mobile > div.android .viewBox img.inner {
  position: absolute;
  top: 6%;
  left: 2.5%;
  max-width: 95%;
  z-index: 1;
  border-radius: 0;
}
@media (max-width: 500px) {
  section[name=caseStudy] .mobile > div.android .viewBox img.inner {
    max-width: 92%;
  }
}
section[name=caseStudy] .mobile > div span {
  margin: 1em 1rem 0 1rem;
}

.react-rotating-text-cursor {
  animation: blinking-cursor 0.8s cubic-bezier(0.68, 0.01, 0.01, 0.99) 0s infinite;
}

@keyframes blinking-cursor {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

