.ContactBlock {
    display: flex;
    flex-direction: column;
    text-align: center;

    border: 1px solid rgba($c-black, 0.1);
    border-radius: 10px;
    font-size: (26/20)*1em;
    padding: 2em;

    &>a {
        color: $c-black;
        font-family: $f-cta;
        font-weight: 500;
        letter-spacing: (1/26)*1em;
        line-height: 150%;
        margin-bottom: 0.5em;

        transition: color 0.3s $ease-in-out-sine;

        &:hover {
            color: $c-primary;
        }
    }

    @media (max-width: 900px) {
        width: 100%;
        margin-top: 2em;
        align-items: center;
        padding-right: 0;
        padding-left: 0;
        padding-bottom: 0;
        border: none;
        border-top: 1px solid rgba($c-black, 0.1);
    }
}
